import styles from "../../../../content/posts/2020-10-04---Instagram multipost/styles.css";
import Example from "../../../../content/posts/2020-10-04---Instagram multipost/example.tsx";
import Example2 from "../../../../content/posts/2020-10-04---Instagram multipost/example2.tsx";
import Columns from "../../../../content/posts/2020-10-04---Instagram multipost/columns.tsx";
import Columns2 from "../../../../content/posts/2020-10-04---Instagram multipost/columns2.tsx";
import Example3 from "../../../../content/posts/2020-10-04---Instagram multipost/example3.tsx";
import * as React from 'react';
export default {
  styles,
  Example,
  Example2,
  Columns,
  Columns2,
  Example3,
  React
};