
import React from "react"

export default () => {
  return (
    <svg
      className="insta-feed side-by-side"
      width="50%"
      viewBox="0 0 682 682"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 1.5}}
    >
      <rect id="Columns" x={0} y={0} width="681.081" height="681.081" style={{fill: 'none'}} />
      <g className="tiles" style={{stroke: 'black'}}>
        <rect x="437.838" y="48.649" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="48.649" y="48.649" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="48.649" y="243.243" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="48.649" y="437.838" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="437.838" y="243.243" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="437.838" y="437.838" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="243.243" y="48.649" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="243.243" y="243.243" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
        <rect x="243.243" y="437.838" width="194.595" height="194.595" style={{fill: 'none', strokeWidth: '2px'}} />
      </g>
      <g className="content">
        <g>
          <path d="M193.802,564.339c-0,-11.951 -5.351,-23.021 -13.717,-31.072l-5.886,5.536c7.194,6.541 11.773,15.724 11.773,25.536c-0,19.624 -17.659,36.103 -38.718,36.103c-20.929,0 -38.587,-16.479 -38.587,-36.103c-0,-9.812 4.578,-18.995 11.641,-25.536l-5.755,-5.536c-8.496,8.051 -13.717,19.121 -13.717,31.072c0,23.901 21.172,44.264 46.418,44.264c25.245,0 46.548,-20.363 46.548,-44.264Z" style={{fill: '#f00', fillRule: 'nonzero'}} />
          <path d="M193.802,480.677c-0,-24.032 -21.303,-44.146 -46.548,-44.146c-25.246,-0 -46.418,20.114 -46.418,44.146c0,23.906 21.172,44.272 46.418,44.272c25.245,0 46.548,-20.366 46.548,-44.272Zm-7.83,0c-0,19.628 -17.659,36.111 -38.718,36.111c-20.929,0 -38.587,-16.483 -38.587,-36.111c-0,-19.628 17.658,-35.985 38.587,-35.985c21.059,-0 38.718,16.357 38.718,35.985Z" style={{fill: '#f00', fillRule: 'nonzero'}} />
          <path d="M184.271,413.384l-81.865,0l-0,8.035l89.826,0l0,-56.089l-7.961,0l0,48.054Z" style={{fill: '#f00', fillRule: 'nonzero'}} />
          <path d="M193.802,315.004c-0,-20.636 -15.677,-35.215 -37.909,-35.215l-53.487,0l-0,8.035l53.487,0c17.655,0 30.209,11.325 30.209,27.18c0,15.855 -12.554,27.18 -30.209,27.18l-53.487,-0l-0,8.035l53.487,-0c22.232,-0 37.909,-14.579 37.909,-35.215Z" style={{fill: '#f00', fillRule: 'nonzero'}} />
          <path d="M192.232,257.13l0,-8.035l-72.044,-0l64.33,-32.967l-64.33,-32.966l72.044,0l0,-8.035l-89.826,-0l-0,8.035l64.33,32.966l-64.33,32.967l-0,8.035l89.826,0Z" style={{fill: '#f00', fillRule: 'nonzero'}} />
          <path d="M192.232,151.462l0,-8.036l-76.228,0l76.228,-55.365l0,-8.036l-89.826,0l-0,8.036l76.228,-0l-76.228,55.365l-0,8.036l89.826,-0Z" style={{fill: '#f00', fillRule: 'nonzero'}} />
        </g>
        <g>
          <path d="M388.396,564.339c0,-11.951 -5.35,-23.021 -13.716,-31.072l-5.886,5.536c7.194,6.541 11.772,15.724 11.772,25.536c0,19.624 -17.658,36.103 -38.718,36.103c-20.928,0 -38.587,-16.479 -38.587,-36.103c0,-9.812 4.578,-18.995 11.642,-25.536l-5.756,-5.536c-8.496,8.051 -13.716,19.121 -13.716,31.072c0,23.901 21.172,44.264 46.417,44.264c25.245,0 46.548,-20.363 46.548,-44.264Z" style={{fill: '#0f0', fillRule: 'nonzero'}} />
          <path d="M388.396,480.677c0,-24.032 -21.303,-44.146 -46.548,-44.146c-25.245,-0 -46.417,20.114 -46.417,44.146c0,23.906 21.172,44.272 46.417,44.272c25.245,0 46.548,-20.366 46.548,-44.272Zm-7.83,0c0,19.628 -17.658,36.111 -38.718,36.111c-20.928,0 -38.587,-16.483 -38.587,-36.111c0,-19.628 17.659,-35.985 38.587,-35.985c21.06,-0 38.718,16.357 38.718,35.985Z" style={{fill: '#0f0', fillRule: 'nonzero'}} />
          <path d="M378.866,413.384l-81.866,0l0,8.035l89.827,0l0,-56.089l-7.961,0l0,48.054Z" style={{fill: '#0f0', fillRule: 'nonzero'}} />
          <path d="M388.396,315.004c0,-20.636 -15.676,-35.215 -37.908,-35.215l-53.488,0l0,8.035l53.488,0c17.654,0 30.209,11.325 30.209,27.18c-0,15.855 -12.555,27.18 -30.209,27.18l-53.488,-0l0,8.035l53.488,-0c22.232,-0 37.908,-14.579 37.908,-35.215Z" style={{fill: '#0f0', fillRule: 'nonzero'}} />
          <path d="M386.827,257.13l0,-8.035l-72.044,-0l64.33,-32.967l-64.33,-32.966l72.044,0l0,-8.035l-89.827,-0l0,8.035l64.33,32.966l-64.33,32.967l0,8.035l89.827,0Z" style={{fill: '#0f0', fillRule: 'nonzero'}} />
          <path d="M386.827,151.462l0,-8.036l-76.228,0l76.228,-55.365l0,-8.036l-89.827,0l0,8.036l76.229,-0l-76.229,55.365l0,8.036l89.827,-0Z" style={{fill: '#0f0', fillRule: 'nonzero'}} />
        </g>
        <g>
          <path d="M582.991,564.339c-0,-11.951 -5.351,-23.021 -13.716,-31.072l-5.887,5.536c7.195,6.541 11.773,15.724 11.773,25.536c-0,19.624 -17.659,36.103 -38.718,36.103c-20.929,0 -38.587,-16.479 -38.587,-36.103c-0,-9.812 4.578,-18.995 11.641,-25.536l-5.755,-5.536c-8.496,8.051 -13.716,19.121 -13.716,31.072c-0,23.901 21.172,44.264 46.417,44.264c25.245,0 46.548,-20.363 46.548,-44.264Z" style={{fill: '#00f', fillRule: 'nonzero'}} />
          <path d="M582.991,480.677c-0,-24.032 -21.303,-44.146 -46.548,-44.146c-25.245,-0 -46.417,20.114 -46.417,44.146c-0,23.906 21.172,44.272 46.417,44.272c25.245,0 46.548,-20.366 46.548,-44.272Zm-7.83,0c-0,19.628 -17.659,36.111 -38.718,36.111c-20.929,0 -38.587,-16.483 -38.587,-36.111c-0,-19.628 17.658,-35.985 38.587,-35.985c21.059,-0 38.718,16.357 38.718,35.985Z" style={{fill: '#00f', fillRule: 'nonzero'}} />
          <path d="M573.461,413.384l-81.866,0l-0,8.035l89.827,0l-0,-56.089l-7.961,0l-0,48.054Z" style={{fill: '#00f', fillRule: 'nonzero'}} />
          <path d="M582.991,315.004c-0,-20.636 -15.677,-35.215 -37.909,-35.215l-53.487,0l-0,8.035l53.487,0c17.655,0 30.21,11.325 30.21,27.18c-0,15.855 -12.555,27.18 -30.21,27.18l-53.487,-0l-0,8.035l53.487,-0c22.232,-0 37.909,-14.579 37.909,-35.215Z" style={{fill: '#00f', fillRule: 'nonzero'}} />
          <path d="M581.422,257.13l-0,-8.035l-72.045,-0l64.33,-32.967l-64.33,-32.966l72.045,0l-0,-8.035l-89.827,-0l-0,8.035l64.33,32.966l-64.33,32.967l-0,8.035l89.827,0Z" style={{fill: '#00f', fillRule: 'nonzero'}} />
          <path d="M581.422,151.462l-0,-8.036l-76.229,0l76.229,-55.365l-0,-8.036l-89.827,0l-0,8.036l76.228,-0l-76.228,55.365l-0,8.036l89.827,-0Z" style={{fill: '#00f', fillRule: 'nonzero'}} />
        </g>
      </g>
      <g className="circles" style={{fill: '#FFF'}}>
        <circle cx="145.946" cy="340.541" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="145.946" cy="145.946" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="340.541" cy="145.946" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="145.946" cy="535.135" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="340.541" cy="535.135" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="340.541" cy="340.541" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="535.135" cy="535.135" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="535.135" cy="340.541" r="48.649" style={{fillOpacity: 0.9}} />
        <circle cx="535.135" cy="145.946" r="48.649" style={{fillOpacity: 0.9}} />
      </g>
      <g className="numbers">
        <path d="M145.605,128.324c-7.084,0 -12.324,5.293 -12.324,11.904c0,6.77 5.397,11.222 11.747,11.222c4.67,0 8.71,-2.407 10.547,-6.079l-0,1.05c-0,9.123 -4.67,16.104 -10.757,16.104c-3.411,0 -6.14,-1.575 -8.448,-3.831l-1.885,2.624c2.724,2.62 6.082,4.295 10.228,4.295c8.081,0 14.212,-8.284 14.212,-19.192c0,-10.6 -4.19,-18.097 -13.32,-18.097Zm0.052,20.143c-4.932,0 -8.973,-3.411 -8.973,-8.448c0,-4.408 3.621,-8.711 8.973,-8.711c5.195,-0 8.344,3.988 9.446,9.918c-0.893,4.198 -4.671,7.241 -9.446,7.241Z" style={{fillRule: 'nonzero'}} />
        <path d="M340.357,165.141c7.084,0 12.796,-4.138 12.796,-10.015c-0,-4.356 -3.456,-7.766 -7.916,-9.232c3.83,-1.309 7.024,-4.3 7.024,-8.498c-0,-5.668 -5.607,-9.544 -11.904,-9.544c-6.192,0 -11.852,3.823 -11.852,9.491c0,3.989 2.932,7.085 6.972,8.551c-4.46,1.413 -7.916,4.823 -7.916,9.232c0,5.877 5.66,10.015 12.796,10.015Zm-0,-20.661c-4.093,-0.209 -8.449,-2.624 -8.449,-6.77c0,-3.831 3.779,-6.769 8.449,-6.769c4.67,-0 8.501,2.938 8.501,6.769c-0,4.094 -4.356,6.561 -8.501,6.77Zm-0,17.573c-5.195,-0 -9.393,-3.044 -9.393,-7.137c0,-4.408 4.933,-7.19 9.393,-7.295c4.513,0.105 9.393,2.887 9.393,7.295c-0,4.093 -4.251,7.137 -9.393,7.137Z" style={{fillRule: 'nonzero'}} />
        <path d="M527.243,164.512l3.828,-0l15.785,-33.465l-0,-2.565l-24.228,-0l-0,3.193l20.19,-0l-15.575,32.837Z" style={{fillRule: 'nonzero'}} />
        <path d="M147.284,336.138c-4.67,-0 -8.711,2.407 -10.547,6.079l-0,-1.049c-0,-9.124 4.67,-16.105 10.757,-16.105c3.411,0 6.139,1.575 8.447,3.831l1.885,-2.624c-2.723,-2.62 -6.082,-4.295 -10.227,-4.295c-8.081,-0 -14.213,8.284 -14.213,19.193c0,10.599 4.19,18.096 13.321,18.096c7.084,-0 12.323,-5.293 12.323,-11.904c0,-6.77 -5.397,-11.222 -11.746,-11.222Zm-0.63,20.142c-5.195,0 -8.343,-3.988 -9.445,-9.917c0.892,-4.198 4.67,-7.242 9.445,-7.242c4.933,-0 8.973,3.411 8.973,8.448c0,4.408 -3.62,8.711 -8.973,8.711Z" style={{fillRule: 'nonzero'}} />
        <path d="M341.079,359.264c7.021,-0 12.362,-4.925 12.362,-11.852c0,-6.717 -5.289,-11.065 -11.576,-11.065c-3.3,0 -6.025,1.1 -8.015,2.565l0.366,-13.115l17.498,0l-0,-3.193l-20.796,0l-0.472,18.406l2.618,1.259c2.357,-1.731 5.134,-2.886 8.068,-2.886c5.082,0 8.906,3.464 8.906,8.186c0,4.828 -3.824,8.606 -8.854,8.606c-4.139,0 -7.806,-1.941 -10.633,-5.09l-2.203,2.467c2.989,3.461 7.388,5.712 12.731,5.712Z" style={{fillRule: 'nonzero'}} />
        <path d="M540.489,359.106l3.35,0l0,-9.074l5.613,-0l0,-3.089l-5.613,0l0,-23.867l-4.032,0l-17.835,24.024l0.472,2.932l18.045,-0l-0,9.074Zm-14.635,-12.163l14.635,-19.834l-0,19.834l-14.635,0Z" style={{fillRule: 'nonzero'}} />
        <path d="M145.975,554.33c7.082,0 12.163,-4.505 12.163,-10.382c0,-4.986 -3.56,-7.818 -7.389,-8.707c3.723,-1.1 6.603,-3.933 6.603,-8.394c-0,-5.668 -4.977,-9.806 -11.534,-9.806c-5.245,0 -9.18,2.565 -12.117,6.5l2.256,2.099c2.727,-3.464 5.717,-5.51 9.651,-5.51c4.669,-0 8.341,3.096 8.341,7.085c-0,4.303 -3.567,6.717 -8.813,6.717l-3.672,-0.053l0,3.141l3.62,0c5.98,0 9.651,2.362 9.651,6.718c0,4.303 -3.671,7.504 -8.76,7.504c-4.458,0 -8.025,-2.099 -10.491,-5.563l-2.36,2.204c2.937,3.883 7.291,6.447 12.851,6.447Z" style={{fillRule: 'nonzero'}} />
        <path d="M328.06,554.173l24.699,0l0,-3.193l-19.454,-0l10.491,-9.077c5.873,-5.037 8.386,-9.076 8.386,-13.798c0,-6.139 -4.924,-10.591 -11.271,-10.591c-5.193,-0 -9.442,2.512 -12.537,6.918l2.413,1.941c2.623,-3.62 5.98,-5.824 10.072,-5.824c4.353,0 7.92,3.201 7.92,7.608c0,3.568 -2.15,7.188 -7.291,11.595l-13.428,11.49l-0,2.931Z" style={{fillRule: 'nonzero'}} />
        <path d="M532.07,553.701l3.35,-0l0,-36.03l-2.722,-0l-9.073,6.031l1.521,2.57l6.924,-4.458l-0,31.887Z" style={{fillRule: 'nonzero'}} />
      </g>
    </svg>
  );
}