/// <reference types="resize-observer-browser" />

import React, { useEffect, useRef, useState } from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import styles from "./WideImage.module.scss";

type Props = {
  src: string;
  alt: string;
  caption: string;
};

const WideImage = ({ src, alt, caption }: Props) => {
  const [height, setHeight] = useState(0)
  const figRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const boundaries = figRef.current.getBoundingClientRect()
    setHeight(boundaries.height + 40)

    const resizeObserver = new ResizeObserver(() => {
      const boundaries = figRef.current.getBoundingClientRect()
      setHeight(boundaries.height + 40)
    });

    resizeObserver.observe(figRef.current);

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <>
      <div className={styles["wideImage__container"]}>
        <div className={styles["wideImage__content"]}>
          <Zoom wrapStyle={{width: '100%'}}>
            <figure ref={figRef}>
              <img src={src} alt={alt} style={{boxShadow: "0px 0px 20px 1px rgba(0,0,0,0.2)"}}/>
              <figcaption>{caption}</figcaption>
            </figure>
          </Zoom>
        </div>
      </div>
      <div style={{
        height
      }}/>
    </>
  );
};

export default WideImage;
