import React, { ReactNode } from "react";
import loadable from "@loadable/component";

const Background = loadable(() => import("../components/Background"));

type Props = {
  children: ReactNode;
  pageContext: {
    background?: string;
    slug?: string;
  };
};

export default ({ children, pageContext }: Props) => {
  console.log("context", pageContext);
  console.log("back", pageContext.background);
  return (
    <>
      {children}
      <Background type={pageContext.background} page={pageContext.slug} />
    </>
  );
};
